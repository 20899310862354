import Dashboard from '../assests/icons/dashboard.svg'
import MyProfile from '../assests/icons/myprofile.svg'
import MyTeam from '../assests/icons/myprofile.svg'

export const SidebarData = [
  { title: "Dashboard", icon: Dashboard },
  { title: "My Profile", icon: MyProfile },
  { title: "Live Class", icon: MyProfile },
  { title: "My Team", icon: MyTeam },
  { title: "Team Discussion", icon: MyProfile },
  { title: "Announcement", icon: MyProfile },
  { title: "IDE", icon: MyProfile },
  { title: "My Quizzes", icon: MyProfile },
  { title: "Events", icon: MyProfile },
  { title: "Assignment", icon: MyProfile },
  { title: "Case Studies", icon: MyProfile },
  { title: "Projects", icon: MyProfile },
  { title: "My Progress", icon: MyProfile },
  { title: "Leaderboard", icon: MyProfile },
  { title: "Community Page", icon: MyProfile },
  { title: "Latest Activity", icon: MyProfile },
];