import React from "react";

export const Success = () => {
  return (
    <div className="flex flex-col h-screen justify-center items-center gap-y-10 ">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="137"
          height="137"
          viewBox="0 0 137 137"
          fill="none"
        >
          <path
            d="M68.5 0C30.7045 0 0 30.7045 0 68.5C0 106.296 30.7045 137 68.5 137C106.296 137 137 106.296 137 68.5C137 30.7045 106.296 0 68.5 0ZM68.5 5.95652C103.076 5.95652 131.043 33.9238 131.043 68.5C131.043 103.076 103.076 131.043 68.5 131.043C33.9238 131.043 5.95652 103.076 5.95652 68.5C5.95652 33.9238 33.9238 5.95652 68.5 5.95652ZM98.2477 38.6825C97.7637 38.692 97.2894 38.8194 96.8657 39.0535C96.4421 39.2876 96.0818 39.6215 95.8162 40.0262L65.4345 84.8106L43.7199 64.6608C43.4347 64.3864 43.0976 64.1715 42.7284 64.0288C42.3591 63.8861 41.9652 63.8185 41.5695 63.8298C41.1738 63.8411 40.7843 63.9312 40.4239 64.0948C40.0634 64.2584 39.7391 64.4921 39.4701 64.7825C39.201 65.0728 38.9925 65.4139 38.8567 65.7857C38.721 66.1576 38.6607 66.5527 38.6795 66.9482C38.6982 67.3436 38.7956 67.7313 38.9659 68.0886C39.1362 68.4459 39.376 68.7658 39.6714 69.0293L66.4641 93.8908L100.749 43.3651C101.063 42.9145 101.247 42.3857 101.28 41.8372C101.312 41.2886 101.193 40.7418 100.934 40.2571C100.675 39.7724 100.287 39.3688 99.8128 39.0909C99.3388 38.813 98.7971 38.6717 98.2477 38.6825Z"
            fill="#34F268"
          />
        </svg>
      </div>
      <div>
        <p className="center text-2xl font-semibold font-serif">
          Payment completed successfully
        </p>
      </div>
      <div>
        <p className="text-lg center">
          An email confirmation will be sent to you shortly with your Payment
          details.
        </p>
      </div>
      <div>
        <button 
        className="border-2 rounded w-[394px] h-[51px] border-[#6834F2] text-[#6834F2]"
        >Go To Dashboard</button>
      </div>
    </div>
  );
};
