import React from "react";

export const Carousel = ({ nxtPage, secondPage, submitForm }) => {

  const submit = () => {
    if(secondPage) submitForm()
    else nxtPage(true)
  };

  return (
    <>
      <button className="bg-primaryColor text-white text-center rounded-lg h-10 w-full mt-8" onClick={submit}>
        { !secondPage ? "Next" :  "submit"}
      </button>
      <div className="flex items-center justify-center">
        <div className="flex space-x-12 p-12">
          <button
            className={`w-3 h-3 rounded-full ${
              !secondPage ? "bg-[#34F268]" : "bg-gray-300"
            }`}
            onClick={() => nxtPage(false)}
          ></button>
          <button
            className={`w-3 h-3 rounded-full ${
              secondPage ? "bg-[#34F268]" : "bg-gray-300"
            }`}
            onClick={() => nxtPage(true)}
          ></button>
        </div>
      </div>
    </>
  );
};
