import React from "react";
import Sidebar from "../../components/Sidebar";
import { BiCheck } from "react-icons/bi";

export default function Dashboard() {
  return (
    <div class="flex min-h-screen">
      <Sidebar></Sidebar>

      <div class="min-w-0 flex-1 overflow-auto p-2">
        <h1 class="font-bold">My Profile</h1>
        <div class="m-2 bg-purple-300">
          <div class="flex items-center p-6">
            <div>
              <img
                class="inline-block h-16 w-16 rounded-full ring ring-white"
                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              />
            </div>
            <div class="flex-1 pl-4">
              <h1 class="font-bold">Vikram Kumar</h1>
              <h1 class="font-thin">vikram@gmail.com</h1>
              <div></div>
              <div class="relative h-full">
                <p class="absolute right-0 top-0 flex items-center text-sm font-thin">
                  <svg
                    class="m-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 512 512"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                  Edit
                </p>
              </div>
            </div>
          </div>

          <div class="bg-white">
            <nav class="tabs flex flex-col sm:flex-row">
              <button
                data-target="panel-1"
                class="tab active block border-b-2 border-purple-600 px-6 py-4 font-medium text-purple-600 hover:text-purple-600 focus:outline-none"
              >
                Profile Details
              </button>
              <button
                data-target="panel-2"
                class="tab block px-6 py-4 text-gray-600 hover:text-purple-600 focus:outline-none"
              >
                Education
              </button>
            </nav>

            <div className="w-">
              <div className="form-group">
                <label className="text-karla text-black text-base font-medium">
                  Name
                </label>
                <input
                  type="text"
                  className="bg-gray-50 text-black px-2 w-full rounded-lg border border-gray-300 h-10 mt-2"
                  placeholder="Enter your name"
                  name="name"
                />
              </div>
              <div className="form-group mt-4">
                <label className="text-karla text-black text-base font-medium">
                  Phone number
                </label>
                <div className="mt-2 flex justify-between items-center gap-5">
                  <input
                    type="text"
                    className="bg-gray-50 text-black px-2 w-full rounded-lg border border-gray-300 h-10"
                    placeholder="Enter your phone number"
                    name="phone-number"
                  />
                  <button className="bg-primaryColor text-3xl text-white rounded-lg h-10 w-12 flex justify-center items-center">
                    <BiCheck />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
          
      </div>
    </div>
  );
}
