import { Routes_Json } from './routes/Routes_Json';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className='App'>
      <Routes>
        {Routes_Json.map((eachRoute) => (
          <Route exact path={eachRoute.path} element={eachRoute.component} />
        ))}
      </Routes>
    </div>
  );
}

export default App;
