import React from "react";
import { BiCheck } from "react-icons/bi";
import Logo from "../../../assests/Logos/logo.png";

export default function Registration({ children, formValue, onFormChange }) {
  return (
    <div className="registration-container">
      <div className="mb-4">
        <div className="hidden md:block">
          <h1 className="font-rubik text-black text-5xl font-normal mb-6">
            Registration
          </h1>
          <p className="font-karla text-black text-xl">
            Welcome to our registration process!
            <br />
            We are thrilled to have you on board
          </p>
        </div>
        <div className="w-full  md:hidden flex justify-center items-center">
          <img className="w-3/5 h-4/6" src={Logo} alt="logo" />
        </div>
      </div>
      <div className="form-container">
        <div className="form-group">
          <label className="text-karla text-black text-base font-medium">
            Name
          </label>
          <input
            type="text"
            className="bg-gray-50 text-black px-2 w-full rounded-lg border border-gray-300 h-10 mt-2"
            placeholder="Enter your name"
            name="name"
            onChange={(e) =>
              onFormChange({ ...formValue, name: e.target.value })
            }
            value={formValue.name}
          />
        </div>
        <div className="form-group mt-4">
          <label className="text-karla text-black text-base font-medium">
            Phone number
          </label>
          <div className="mt-2 flex justify-between items-center gap-5">
            <input
              type="text"
              className="bg-gray-50 text-black px-2 w-full rounded-lg border border-gray-300 h-10"
              placeholder="Enter your phone number"
              name="phone-number"
              onChange={(e) =>
                onFormChange({ ...formValue, phoneNumber: e.target.value })
              }
              value={formValue.phoneNumber}
            />
            <button className="bg-primaryColor text-3xl text-white rounded-lg h-10 w-12 flex justify-center items-center">
              <BiCheck />
            </button>
          </div>
        </div>
        <div className="form-group mt-4">
          <label
            className="text-karla text-black text-base font-medium"
            name="phone-number"
          >
            Enter OTP
          </label>
          <div className="mt-4 flex justify-between gap-1 text-center">
            {Array(6)
              .fill("")
              .map((_, index) => (
                <input
                  className="border border-gray-300 h-10 w-10 text-center bg-gray-50 text-black  form-control rounded"
                  type="text"
                  id="first"
                  maxLength="1"
                  key={index}
                  value={formValue.otp[index]}
                  onChange={(e) => {
                    const otp = [...formValue.otp];
                    otp[index] = e.target.value;
                    onFormChange({ ...formValue, otp });
                  }}
                />
              ))}
          </div>
        </div>
        <div className="form-group mt-4">
          <label className="text-karla text-black text-base font-medium">
            Email
          </label>
          <input
            type="email"
            className="bg-gray-50 text-black px-2 w-full rounded-lg border border-gray-300 h-10 mt-2"
            placeholder="Enter your email address"
            name="email"
            onChange={(e) =>
              onFormChange({ ...formValue, email: e.target.value })
            }
            value={formValue.email}
          />
        </div>
        <div className="form-group mt-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-primaryColor"
              name="terms"
              onChange={(e) =>
                onFormChange({ ...formValue, isTermsAgreed: e.target.checked })
              }
              defaultChecked={formValue.isTermsAgreed}
            />
            <label className="ml-2 text-karla text-black text-base font-medium">
              I agree to the Terms and Conditions
            </label>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
