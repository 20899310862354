import React from 'react';
import { BiCheck } from 'react-icons/bi';
import Logo from '../../assests/Logos/logo.png';

export default function Login() {
  return (
    <div className='grid grid-flow-col-1 md:grid-cols-2 h-screen'>
      <div className='flex flex-row justify-center items-center px-5 md:px-2'>
        <div className='login-container'>
          <div className='mb-14'>
            <div className='hidden md:block'>
              <h1 className='font-rubik text-black text-5xl font-normal mb-6'>
                Welcome back !
              </h1>
              <p className='font-karla text-black text-2xl'>
                Learn skills from top mentors
              </p>
            </div>
            <div className='w-full  md:hidden flex justify-center items-center '>
              <img className='w-3/5 h-4/6' src={Logo} alt='logo' />
            </div>
          </div>
          <div className='form-container'>
            <div className='form-group'>
              <label className='text-karla text-black text-base font-medium'>
                To Login please enter your phone number
              </label>
              <div className='mt-4 flex justify-between items-center gap-5'>
                <input
                  type='text'
                  className='bg-gray-50 text-black px-2 w-full rounded-lg  border border-gray-300 h-10 '
                  placeholder='Phone number'
                />
                <button className='bg-primaryColor text-3xl text-white rounded-lg h-10 w-12 flex justify-center items-center '>
                  <BiCheck />
                </button>
              </div>
            </div>
            <div className='form-group mt-11'>
              <label className='text-karla text-black text-base font-medium'>
                Enter OTP
              </label>
              <div className='mt-4 flex justify-between gap-1 text-center'>
                <input
                  className='border border-gray-300 h-10 w-10 text-center bg-gray-50 text-black  form-control rounded'
                  type='text'
                  id='first'
                  maxLength='1'
                />
                <input
                  className=' border border-gray-300 h-10 w-10 text-center bg-gray-50 text-black form-control rounded'
                  type='text'
                  id='second'
                  maxLength='1'
                />
                <input
                  className=' border border-gray-300 h-10 w-10 text-center bg-gray-50 text-black form-control rounded'
                  type='text'
                  id='third'
                  maxLength='1'
                />
                <input
                  className='border border-gray-300 h-10 w-10 text-center bg-gray-50 text-black form-control rounded'
                  type='text'
                  id='fourth'
                  maxLength='1'
                />
                <input
                  className=' border border-gray-300 h-10 w-10 text-center bg-gray-50 text-black form-control rounded'
                  type='text'
                  id='fifth'
                  maxLength='1'
                />
                <input
                  className='border border-gray-300 h-10 w-10 text-center bg-gray-50 text-black form-control rounded'
                  type='text'
                  id='sixth'
                  maxLength='1'
                />
              </div>
            </div>
            <button className='bg-primaryColor text-white text-center rounded-lg h-10 w-full mt-8'>
              Log In
            </button>
          </div>
          <div className='form-bottom-content flex items-center justify-center mt-4 gap-1 '>
            <p className='text-base text-gray-500 font-medium'>
              Don’ have an account ?
            </p>
            <p className='text-base text-primaryColor cursor-pointer font-medium'>
              Sign Up
            </p>
          </div>
        </div>
      </div>
      <div className='bg-gray-400 hidden md:block'>2</div>
    </div>
  );
}
