import React from "react";
import Logo from "../../../assests/Logos/logo.png";

export default function CourseEnrollment({
  children,
  formValue,
  onFormChange,
}) {
  return (
    <div className="enrollment-container">
      <div className="mb-4">
        <div className="hidden md:block">
          <h1 className="font-rubik text-black text-4xl font-normal mb-1">
            Course Enrolment
          </h1>
          <p className="font-karla text-black text-sm mb-1">
            Welcome to our registration process!
            <br />
            We are thrilled to have you on board
          </p>
        </div>
        <div className="w-full md:hidden flex justify-center items-center">
          <img className="w-1/2 h-3/4" src={Logo} alt="logo" />
        </div>
      </div>
      <div className="form-container">
        <div className="form-group">
          <label className="text-karla text-black text-xs font-small">
            Select Course
          </label>
          <select
            className="bg-gray-50 text-black px-1 w-full rounded-lg border border-gray-300 h-8 mt-1"
            onChange={(e) =>
              onFormChange({ ...formValue, course: e.target.value })
            }
            value={formValue.course}
          >
            <option value="default" enabled>
              Choose a course...
            </option>
            <option value="fullstack" enabled>
              FullStack Development
            </option>
            <option value="frontend" enabled>
              Frontend Developer
            </option>
            <option value="backend" enabled>
              Backend Developer
            </option>
          </select>
        </div>
        <div className="form-group mt-1">
          <label className="text-karla text-black text-xs font-small">
            Select Team Size
          </label>
          <div className="mt-1 flex gap-2">
            {["Solo", "Duo", "Trio", "Squad", "Mega"].map((teamType) => (
              <button
                key={teamType}
                name={teamType}
                value={teamType}
                type="button"
                className={`appearance-none text-karla text-xs flex items-center justify-center border border-gray-300 p-4 text-center bg-gray-50 text-black form-control rounded ${
                  formValue.teamSize === teamType
                    ? "bg-primaryColor text-white"
                    : "bg-gray-50 text-black"
                }}`}
                onClick={(e) =>
                  onFormChange({ ...formValue, teamSize: e.target.value })
                }
              >
                {teamType}
              </button>
            ))}
          </div>
        </div>
        <div className="form-group mt-4">
          <label className="text-karla text-black text-base font-medium">
            Team Details
          </label>
          <div className="mt-2"></div>
        </div>
        <div className="form-group mt-4">
          <label className="text-karla text-black text-base font-medium">
            Team Member 1
          </label>
          <div className="mt-2"></div>
        </div>
        <div className="form-group mt-4">
          <label className="text-karla text-black text-base font-medium">
            Name
          </label>
          <input
            type="Name"
            className="bg-gray-50 text-black px-2 w-full rounded-lg border border-gray-300 h-10 mt-2"
            placeholder="Enter your Name"
            name="name"
            value={formValue.teamMembers.firstMember.name}
            onChange={(e) =>
              onFormChange({
                ...formValue,
                teamMembers: {
                  ...formValue.teamMembers,
                  firstMember: {
                    ...formValue.teamMembers.firstMember,
                    name: e.target.value,
                  },
                },
              })
            }
          />
        </div>
        <div className="form-group mt-4">
          <label className="text-karla text-black text-base font-medium">
            Email
          </label>
          <input
            type="email"
            className="bg-gray-50 text-black px-2 w-full rounded-lg border border-gray-300 h-10 mt-2"
            placeholder="Enter your email address"
            name="email"
            value={formValue.teamMembers.firstMember.email}
            onChange={(e) =>
              onFormChange({
                ...formValue,
                teamMembers: {
                  ...formValue.teamMembers,
                  firstMember: {
                    ...formValue.teamMembers.firstMember,
                    email: e.target.value,
                  },
                },
              })
            }
          />
        </div>
        <div className="form-group mt-4">
          <label className="text-karla text-black text-base font-medium">
            Team Member 2
          </label>
          <div className="mt-2"></div>
        </div>
        <div className="form-group mt-4">
          <label className="text-karla text-black text-base font-medium">
            Name
          </label>
          <input
            type="Name"
            className="bg-gray-50 text-black px-2 w-full rounded-lg border border-gray-300 h-10 mt-2"
            placeholder="Enter your Name"
            name="name"
            value={formValue.teamMembers.secondMember.name}
            onChange={(e) =>
              onFormChange({
                ...formValue,
                teamMembers: {
                  ...formValue.teamMembers,
                  secondMember: {
                    ...formValue.teamMembers.secondMember,
                    name: e.target.value,
                  },
                },
              })
            }
          />
        </div>
        <div className="form-group mt-4">
          <label className="text-karla text-black text-base font-medium">
            Email
          </label>
          <input
            type="email"
            className="bg-gray-50 text-black px-2 w-full rounded-lg border border-gray-300 h-10 mt-2"
            placeholder="Enter your email address"
            name="email"
            value={formValue.teamMembers.secondMember.email}
            onChange={(e) =>
              onFormChange({
                ...formValue,
                teamMembers: {
                  ...formValue.teamMembers,
                  secondMember: {
                    ...formValue.teamMembers.secondMember,
                    email: e.target.value,
                  },
                },
              })
            }
          />
        </div>
        {children}
      </div>
    </div>
  );
}
