import React from 'react'
import { SidebarData } from './Sidebar.data'
import Logo from "../assests/Logos/logo.png";
export default function Sidebar() {

  return (
    <nav class="left-0 top-0 h-screen w-60 overflow-y-scroll flex-none bg-white">
    <div class="logo flex items-center p-6">
      <img src={Logo} />
    </div>
    <ul class="mt-8 flex flex-col items-center">
      {
        SidebarData.map(data => 
          <li class="h-14 w-full">
          <a href="#" class="bg-link-hover active relative flex h-full w-full items-center p-5 text-gray-400">
            <img src={data.icon} />
            <span class="link-label">{data.title}</span>
          </a>
        </li>
          )

      }
    </ul>
  </nav>
  )
}
