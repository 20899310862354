import React from "react";
import { Carousel } from "../../components/Carousel";
import { useState } from "react";
import CourseEnrollment from "./Enrolment/Enrolment";
import Registration from "./Register/Registration";
export default function Register() {
  const [secondPage, setSPage] = useState(false);
  const [registerFormValue, setRegisterFormValue] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    otp: Array(6).fill(""),
    isTermsAgreed: false,
  });

  const [enrollmentFormValue, setEnrollmentFormValue] = useState({
    course: "",
    teamSize: "",
    teamMembers: {
      firstMember: {
        name: "",
        email: "",
      },
      secondMember: {
        name: "",
        email: "",
      },
    },
  });

  const submit = () => {
    console.log({...enrollmentFormValue, ...registerFormValue})
  }

  return (
    <div className="grid grid-flow-col-1 md:grid-cols-2 h-screen">
      <div className="flex flex-row justify-center items-center px-5 py-5 md:px-2">
        {!secondPage ? (
          <Registration
            formValue={registerFormValue}
            onFormChange={setRegisterFormValue}
          >
            <Carousel
              nxtPage={(p) => setSPage(p)}
              secondPage={secondPage}
            ></Carousel>
          </Registration>
        ) : (
          <CourseEnrollment
            formValue={enrollmentFormValue}
            onFormChange={setEnrollmentFormValue}
          >
            <Carousel
              submitForm={()=>submit()}
              nxtPage={(p) => setSPage(p)}
              secondPage={secondPage}
            ></Carousel>
          </CourseEnrollment>
        )}
      </div>
      <div className="bg-gray-400 hidden md:block"></div>
    </div>
  );
}
