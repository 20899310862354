import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Register from "../pages/Registration/Register";
import { Success } from "../pages/Success/Success";
import Dashboard from "../pages/Dashboard/Dashboard";

export const Routes_Json = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/Register",
    component: <Register />,
  },
  {
    path: "/Success",
    component: <Success />,
  },
  {
    path: "/Dashboard",
    component: <Dashboard />,
  },
];